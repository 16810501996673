import React from 'react';
import {Helmet} from 'react-helmet'
// import { useState, useEffect } from "react";
import "../App.css";

const Home = () => {

    return(
      <div>
      <Helmet>
        <body className="image bg-light"></body>
      </Helmet>
    <nav className="navbar navbar-expand-sm navbar-light bg-light">
      <div className="container-fluid nav-container">
        <a className="navbar-brand home-text" href="/home">
          <div className="title m-0">LARABELLE</div>
          <div className="small-text m-0">PHOTOGRAPHY</div>
        </a>
        <button
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarNav"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link home-text" href="/home">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link home-text" href="/about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link home-text" href="/sessions">
                Sessions
              </a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link home-text" data-toggle="dropdown" href="/portfolio">
                Portfolio
              </a>
              <div className="dropdown-menu bg-light dropdown-style">
                        <a href="/small-business-portfolio" className="dropdown-item dropdown-style btn btn-light">Small Business</a>
                        <a href="/lifestyle-portfolio" className="dropdown-item dropdown-style btn btn-light">Lifestyle</a>
                        <a href="/fashion-portfolio" className="dropdown-item dropdown-style btn btn-light">Fashion</a>
                        <a href="/videography-portfolio" className="dropdown-item dropdown-style btn btn-light">Videography</a>
                    </div>
            </li>
            <li className="nav-item">
              <a className="nav-link home-text" href="/contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    </div>
    )
}

export default Home;