import React, {useState} from 'react';
import Amber001 from "../pics/Influencer/Amber001.jpg";
import Justin010 from "../pics/Influencer/Justin010.JPG";
import MollyErtel023 from '../pics/Influencer/molly-ertel023.JPG';
import MosesChristopher001 from '../pics/Influencer/moses-christoper001.JPG';
import NijiAdeleye138 from "../pics/Influencer/niji-adeleye138.JPG";
import NoriRane002 from "../pics/Influencer/nori-rane002.JPG";
import Anna001 from "../pics/Influencer/Anna001.JPG";
import Anna002 from "../pics/Influencer/Anna002.JPG";
import Bianca001 from "../pics/Influencer/Bianca001.jpg";
import Emma001 from "../pics/Influencer/Emma001.JPG";
import Gaelle001 from "../pics/Influencer/Gaelle001.JPG";
import Heather001 from "../pics/Influencer/Heather001.jpg";
import Kayla001 from "../pics/Influencer/Kayla001.JPG";
import Megan001 from "../pics/Influencer/Megan001.JPG";
import Nory001 from "../pics/Influencer/Nory001.JPG";
import Yoa001 from "../pics/Influencer/Yoa001.JPG";
import ModalFashionPort from "../components/ModalFashionPort"

const IpadViewFashionPort = () => {

  const [currentPhoto, setCurrentPhoto] = useState(null);


    return(
        <div className="d-flex flex-wrap">
          <div className="container">
            <div className="row">
              <div className="card-columns col-sm-15 col-md-15 col-lg-15 col-xl-15 mt-3 d-flex justify-content-center mr-20 ml-20">
                <div className="card">
                  <img className="card-img" onClick={() => {
                    setCurrentPhoto(Amber001);

                    }} src={Amber001} alt="amber001" type="button" data-toggle="modal" data-target="#exampleModalCenter"/>
                  <ModalFashionPort photo={currentPhoto} />
                  <div className="card-body d-flex flex-column">
                    <a
                      className="fab fa-instagram icon-style d-flex justify-content-end pb-2"
                      target="_blank"
                      href="https://www.instagram.com/stylepluscurves/"
                      rel="noopener noreferrer" 
                    >
                      <React.Fragment/>
                    </a>
                    <h4 className="card-title title-font d-flex justify-content-center pb-2">
                      Amber McCulloch
                    </h4>
                  </div>
                </div>
                <div className="card">
                  <img className="card-img" onClick={() => {setCurrentPhoto(Justin010)}} src={Justin010} alt="justin010" data-toggle="modal" data-target="#exampleModalCenter"/>
                  <ModalFashionPort photo={currentPhoto} />
                  <div className="card-body d-flex flex-column">
                    <a
                      className="fab fa-instagram icon-style d-flex justify-content-end pb-2"
                      target="_blank"
                      href="https://www.instagram.com/jwuglow/"
                      rel="noopener noreferrer"
                    >
                        <React.Fragment/>
                    </a>
                    <h4 className="card-title title-font d-flex justify-content-center pb-2">
                      Justin Uglow
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="card-columns col-sm-15 col-md-15 col-lg-15 col-xl-15 d-flex justify-content-center mr-20 ml-20">
                <div className="card">
                <img className="card-img" onClick={() => {setCurrentPhoto(Bianca001)}} src={Bianca001} alt="bianca001" type="button" data-toggle="modal" data-target="#exampleModalCenter"/>
                  <ModalFashionPort photo={currentPhoto} />
                  <div className="card-body d-flex flex-column">
                    <a
                      className="fab fa-instagram icon-style d-flex justify-content-end pb-2"
                      target="_blank"
                      href="https://www.instagram.com/bperformer/"
                      rel="noopener noreferrer"
                    >
                      <React.Fragment/>
                    </a>
                    <h4 className="card-title title-font d-flex justify-content-center pb-2">
                      Bianca Muniz
                    </h4>
                  </div>
                </div>
                <div className="card">
                <img className="card-img" onClick={() => {setCurrentPhoto(Emma001)}} src={Emma001} alt="emma001" type="button" data-toggle="modal" data-target="#exampleModalCenter"/>
                  <ModalFashionPort photo={currentPhoto} />
                  <div className="card-body d-flex flex-column">
                    <a
                      className="fab fa-instagram icon-style d-flex justify-content-end pb-2"
                      target="_blank"
                      href="https://www.instagram.com/emma.siaut/?hl=en"
                      rel="noopener noreferrer"
                    >
                      <React.Fragment/>
                    </a>
                    <h4 className="card-title title-font d-flex justify-content-center pb-2">
                      Emma Siaut
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="card-columns col-sm-15 col-md-15 col-lg-15 col-xl-15 d-flex justify-content-center mr-20 ml-20">
              <div className="card">
                  <img className="card-img" onClick={() => {setCurrentPhoto(MosesChristopher001)}} src={MosesChristopher001} alt="moseschristopher001" data-toggle="modal" data-target="#exampleModalCenter"/>
                  <ModalFashionPort photo={currentPhoto} />
                  <div className="card-body d-flex flex-column">
                    <a
                      className="fab fa-instagram icon-style d-flex justify-content-end pb-2"
                      target="_blank"
                      href="https://www.instagram.com/moseschristopher/"
                      rel="noopener noreferrer"
                    >
                        <React.Fragment/>
                    </a>
                    <h4 className="card-title title-font d-flex justify-content-center pb-2">
                      Moses Christopher
                    </h4>
                  </div>
                </div>
                <div className="card">
                <img className="card-img" onClick={() => {setCurrentPhoto(Anna002)}} src={Anna002} alt="anna002" type="button" data-toggle="modal" data-target="#exampleModalCenter"/>
                  <ModalFashionPort photo={currentPhoto} />
                  <div className="card-body d-flex flex-column">
                    <a
                      className="fab fa-instagram icon-style d-flex justify-content-end pb-2"
                      target="_blank"
                      href="https://www.instagram.com/glitterandlazers/"
                      rel="noopener noreferrer" 
                    >
                      <React.Fragment/>
                    </a>
                    <h4 className="card-title title-font d-flex justify-content-center pb-2">
                      Anna O'Brien
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="card-columns col-sm-15 col-md-15 col-lg-15 col-xl-15 d-flex justify-content-center mr-20 ml-20">
                <div className="card">
                <img className="card-img" onClick={() => {setCurrentPhoto(Heather001)}} src={Heather001} alt="heather001" type="button" data-toggle="modal" data-target="#exampleModalCenter"/>
                  <ModalFashionPort photo={currentPhoto} />
                  <div className="card-body d-flex flex-column">
                    <a
                      className="fab fa-instagram icon-style d-flex justify-content-end pb-2"
                      target="_blank"
                      href="https://www.instagram.com/heatherlarose/"
                      rel="noopener noreferrer"
                    >
                      <React.Fragment/>
                    </a>
                    <h4 className="card-title title-font d-flex justify-content-center pb-2">
                      Heather Larose
                    </h4>
                  </div>
                </div>
                <div className="card">
                <img className="card-img" onClick={() => {setCurrentPhoto(Kayla001)}} src={Kayla001} alt="kayla001" type="button" data-toggle="modal" data-target="#exampleModalCenter"/>
                  <ModalFashionPort photo={currentPhoto} />
                  <div className="card-body d-flex flex-column">
                    <a
                      className="fab fa-instagram icon-style d-flex justify-content-end pb-2"
                      target="_blank"
                      href="https://www.instagram.com/kaylaschaos/"
                      rel="noopener noreferrer"
                    >
                      <React.Fragment/>
                    </a>
                    <h4 className="card-title title-font d-flex justify-content-center pb-2">
                      Kayla Boyd
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="card-columns col-sm-15 col-md-15 col-lg-15 col-xl-15 d-flex justify-content-center mr-20 ml-20">
                <div className="card">
                <img className="card-img" onClick={() => {setCurrentPhoto(Megan001)}} src={Megan001} alt="megan001" type="button" data-toggle="modal" data-target="#exampleModalCenter" />
                  <ModalFashionPort photo={currentPhoto} />
                  <div className="card-body d-flex flex-column">
                    <a
                      className="fab fa-instagram icon-style d-flex justify-content-end pb-2"
                      target="_blank"
                      href="https://www.instagram.com/bodyposipanda/?hl=en"
                      rel="noopener noreferrer"
                    >
                      <React.Fragment/>
                    </a>
                    <h4 className="card-title title-font d-flex justify-content-center pb-2">
                      Megan Jayne Crabbe
                    </h4>
                  </div>
                </div>
                <div className="card">
                  <img className="card-img" onClick={() => {setCurrentPhoto(NijiAdeleye138)}} src={NijiAdeleye138} alt="nijiadeleye138" data-toggle="modal" data-target="#exampleModalCenter" />
                  <ModalFashionPort photo={currentPhoto} />
                  <div className="card-body d-flex flex-column">
                    <a
                      className="fab fa-instagram icon-style d-flex justify-content-end pb-2"
                      target="_blank"
                      href="https://www.instagram.com/nijiadeleye/"
                      rel="noopener noreferrer"
                    >
                        <React.Fragment/>
                    </a>
                    <h4 className="card-title title-font d-flex justify-content-center pb-2">
                      Niji Adeleye
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="card-columns col-sm-15 col-md-15 col-lg-15 col-xl-15 d-flex justify-content-center mr-20 ml-20">
                <div className="card">
                  <img className="card-img" onClick={() => {setCurrentPhoto(Nory001)}} src={Nory001} alt="nory001" type="button" data-toggle="modal" data-target="#exampleModalCenter"/>
                  <ModalFashionPort photo={currentPhoto} />
                  <div className="card-body d-flex flex-column">
                    <a
                      className="fab fa-instagram icon-style d-flex justify-content-end pb-2"
                      target="_blank"
                      href="https://www.instagram.com/nycnory/?hl=en"
                      rel="noopener noreferrer"
                    >
                      <React.Fragment/>
                    </a>
                    <h4 className="card-title title-font d-flex justify-content-center pb-2">
                      Nory
                    </h4>
                  </div>
                </div>
                <div className="card">
                <img className="card-img" onClick={() => {setCurrentPhoto(Gaelle001)}} src={Gaelle001} alt="gaelle001" type="button" data-toggle="modal" data-target="#exampleModalCenter"/>
                  <ModalFashionPort photo={currentPhoto} />
                  <div className="card-body d-flex flex-column">
                    <a
                      className="fab fa-instagram icon-style d-flex justify-content-end pb-2"
                      target="_blank"
                      href="https://www.instagram.com/gaelleprudencio/?hl=en"
                      rel="noopener noreferrer"
                    >
                      <React.Fragment/>
                    </a>
                    <h4 className="card-title title-font d-flex justify-content-center pb-2">
                      Gaëlle Prudencio
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="card-columns col-sm-15 col-md-15 col-lg-15 col-xl-15 d-flex justify-content-center mr-20 ml-20">
              <div className="card">
                <img className="card-img" onClick={() => {setCurrentPhoto(Yoa001)}} src={Yoa001} alt="yoa001" type="button" data-toggle="modal" data-target="#exampleModalCenter" />
                  <ModalFashionPort photo={currentPhoto} />
                  <div className="card-body d-flex flex-column">
                    <a
                      className="fab fa-instagram icon-style d-flex justify-content-end pb-2"
                      target="_blank"
                      href="https://www.instagram.com/yoamizuno/?hl=en"
                      rel="noopener noreferrer"
                    >
                      <React.Fragment/>
                    </a>
                    <h4 className="card-title title-font d-flex justify-content-center pb-2">
                      Yoa Mizuno
                    </h4>
                  </div>
                </div>
                <div className="card">
                  <img className="card-img" onClick={() => {setCurrentPhoto(NoriRane002)}} src={NoriRane002} alt="norirane002" data-toggle="modal" data-target="#exampleModalCenter"/>
                  <ModalFashionPort photo={currentPhoto} />
                  <div className="card-body d-flex flex-column">
                    <a
                      className="fab fa-instagram icon-style d-flex justify-content-end pb-2"
                      target="_blank"
                      href="https://www.instagram.com/noriranemua/"
                      rel="noopener noreferrer"
                    >
                        <React.Fragment/>
                    </a>
                    <h4 className="card-title title-font d-flex justify-content-center pb-2">
                      Nori Rane
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default IpadViewFashionPort;