import React from "react";
import Helmet from "react-helmet";
import { useState, useEffect } from "react";
import IpadViewFashionPort from "./IpadViewFashionPort";
import NormalViewFashionport from './NormalViewFashionPort';


const FashionPort = () => {
  const [isIpad, setIpad] = useState(window.innerWidth >= 768 && window.innerWidth <= 1020);

  const updateMedia = () => {
    setIpad(window.innerWidth >= 768 && window.innerWidth <= 1020);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="bg-light container-fluid col">
      <Helmet>
        <body className="bg-light"></body>
      </Helmet>
      {isIpad ? (
        <IpadViewFashionPort/>
      ) : (
        <NormalViewFashionport/>
      )}
    </div>
  );
};

export default FashionPort;
