import React from "react";
import { Helmet } from "react-helmet";
import smallBusiness from "../pics/smallBusiness.JPG";
import lifestyleSessions from "../pics/lifestyleSessions.JPG";
import fashionSession from "../pics/fashionSession.JPG";

const Sessions = () => {
  return (
    <div className="page-box bg-light">
      <Helmet>
        <body className="bg-light"></body>
      </Helmet>
      <div className="container w-60 h-50 writing-text mb-4 card-rows style justifiy-content-center">
        <div className="row mb-4">
          <div className="col-sm-4 col-md-6 col-lg-7 col-xl-8">
            <img
              className="card-img-top img-fluid"
              src={smallBusiness}
              alt="person"
            />
          </div>
          <div className="col-sm-7 col-md-5 col-lg-4 col-xl-3">
            <div className="card-body w-60">
              <h4 className="card-title">Small Business Session</h4>
              <p className="card-text">Average Investment $2,000</p>
              <p className="card-text">
                <small className="text-muted sessions-text">
                From fun Tiktok videos to high quality images, an online presence is more important than ever if you are hoping to grow your small business. Let's work together to customize a package that best suits the needs of your company. 
                </small>
              </p>
              <a href="/small-business-portfolio">
                <button className="btn btn-light btn-outline-secondary mr-5">
                  View Small Business Sessions
                </button>
                <a href="/contact">
                <button className="btn btn-light btn-outline-secondary mt-3">
                  Book Session
                </button>
                </a>
              </a>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-4 col-md-6 col-lg-7 col-xl-8">
            <img
              className="card-img-top img-fluid"
              src={fashionSession}
              alt="person"
            />
          </div>
          <div className="col-sm-7 col-md-5 col-lg-4 col-xl-3">
            <div className="card-body w-60">
              <h4 className="card-title">Fashion Session</h4>
              <p className="card-text">Average Investment $1,000</p>
              <p className="card-text">
                <small className="text-muted sessions-text">
                If you are a designer, influencer, actress, or model who is passionate about promoting your art with unaltered images this session is an ideal fit for you. 
The fashion session includes several consultations, location scouting, and mood board creation to ensure our visions align to create beautiful imagery.
                </small>
              </p>
              <a href="/fashion-portfolio">
                <button className="btn btn-light btn-outline-secondary mr-5">
                  View Fashion Sessions
                </button>
                <a href="/contact">
                <button className="btn btn-light btn-outline-secondary mt-3">
                  Book Session
                </button>
                </a>
              </a>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-4 col-md-6 col-lg-7 col-xl-8">
            <img
              className="card-img-top img-fluid"
              src={lifestyleSessions}
              alt="person"
            />
          </div>
          <div className="col-sm-7 col-md-5 col-lg-4 col-xl-3">
            <div className="card-body w-60">
              <h4 className="card-title">Lifestyle Session</h4>
              <p className="card-text">Average Investment $600</p>
              <p className="card-text">
                <small className="text-muted sessions-text">
                Even if you have very little experience in front of a camera, you do not have to be a professional model to feel beautiful in your photos! The Lifestyle sessions cover any event from a wedding to senior portraits or a family photo shoot. Keep an eye on the Instagram page for pop up Mini Session offerings as well.
                </small>
              </p>
              <a href="/lifestyle-portfolio">
                <button className="btn btn-light btn-outline-secondary mr-5">
                  View Lifestyle Sessions
                </button>
                <a href="/contact">
                <button className="btn btn-light btn-outline-secondary mt-3">
                  Book Session
                </button>
                </a>
              </a>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-4 col-md-6 col-lg-7 col-xl-8">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                width="570"
                height="315"
                src="https://www.youtube.com/embed/WP543JdcgUs"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="videography"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="col-sm-7 col-md-5 col-lg-4 col-xl-3">
            <div className="card-body w-60">
              <h4 className="card-title">Videography Session</h4>
              <p className="card-text">Average Investment $3000</p>
              <p className="card-text">
                <small className="text-muted sessions-text">
                If you are an artist with a passion for realistic, cinematic imagery we will work together to tell a visual story that will bring your art to life. Since the current digital landscape puts such a strong emphasis on the need for visual imagery to accompany an audio recording, let's collaborate to create an affordable package that will give your art the best chance of reaching a large audience. 
                </small>
              </p>
              <a href="/videography-portfolio">
                <button className="btn btn-light btn-outline-secondary mr-5 mb-3">
                  View Videography Sessions
                </button>
                </a>
                <a href="/contact">
                <button className="btn btn-light btn-outline-secondary">
                  Book Session
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sessions;
