import React, { useState } from "react";
import Helmet from "react-helmet";
import ModalFashionPort from "../components/ModalFashionPort";

const imagesLifestyle = [
  {
    id: 1,
    imageName: require("../pics/Lifestyle/Alicia023.JPG"),
    content: "Alicia023",
    display: "portrait",
  },
  {
    id: 2,
    imageName: require("../pics/Lifestyle/Alicia029.JPG"),
    content: "Alicia029",
    display: "landscape",
  },
  {
    id: 3,
    imageName: require("../pics/Lifestyle/AliciaB&W028.JPG"),
    content: "Alicia028",
    display: "landscape",
  },
  {
    id: 4,
    imageName: require("../pics/Lifestyle/Amy036.JPG"),
    content: "Amy036",
    display: "portrait",
  },
  {
    id: 5,
    imageName: require("../pics/Lifestyle/AntwanLatoya0019.JPG"),
    content: "AntwanLatoya0019",
    display: "landscape",
  },
  {
    id: 6,
    imageName: require("../pics/Lifestyle/B&WAutumnBryant003.JPG"),
    content: "B&WAutumnBryant003",
    display: "landscape",
  },
  {
    id: 7,
    imageName: require("../pics/Lifestyle/Caroline005.JPG"),
    content: "Caroline005",
    display: "portrait",
  },
  {
    id: 8,
    imageName: require("../pics/Lifestyle/Chantale002.JPG"),
    content: "Caroline002",
    display: "landscape",
  },
  {
    id: 9,
    imageName: require("../pics/Lifestyle/Chantale003.JPG"),
    content: "Caroline003",
    display: "landscape",
  },
  {
    id: 10,
    imageName: require("../pics/Lifestyle/Danielle016.JPG"),
    content: "Danielle016",
    display: "landscape",
  },
  {
    id: 11,
    imageName: require("../pics/Lifestyle/BreatheStills011.JPG"),
    content: "BreatheStills011",
    display: "landscape",
  },
  {
    id: 12,
    imageName: require("../pics/Lifestyle/Solange004.JPG"),
    content: "Solange004",
    display: "landscape",
  },
  {
    id: 13,
    imageName: require("../pics/Lifestyle/Davecandy003.JPG"),
    content: "Davecandy003",
    display: "landscape",
  },
  {
    id: 14,
    imageName: require("../pics/Lifestyle/SophiaandIsabelle053.JPG"),
    content: "SophiaandIsabelle053",
    display: "landscape",
  },
  {
    id: 15,
    imageName: require("../pics/Lifestyle/ZadaandKelly026.JPG"),
    content: "ZadaandKelly026",
    display: "portrait",
  },
  {
    id: 16,
    imageName: require("../pics/Lifestyle/EmilySenior023.JPG"),
    content: "EmilySenior023",
    display: "portrait",
  },
  {
    id: 17,
    imageName: require("../pics/Lifestyle/EmilySenior069.JPG"),
    content: "EmilySenior069",
    display: "portrait",
  },
  {
    id: 18,
    imageName: require("../pics/Lifestyle/Golf054.JPG"),
    content: "Golf054",
    display: "portrait",
  },
  {
    id: 19,
    imageName: require("../pics/Lifestyle/Kevelyn012.JPG"),
    content: "Kevelyn012",
    display: "landscape",
  },
  {
    id: 20,
    imageName: require("../pics/Lifestyle/Neelix001.JPG"),
    content: "Neelix001",
    display: "portrait",
  },
  {
    id: 21,
    imageName: require("../pics/Lifestyle/RachelCemetery046.JPG"),
    content: "RachelCemetery046",
    display: "landscape",
  },
  {
    id: 22,
    imageName: require("../pics/Lifestyle/Taylorfirstlook015.JPG"),
    content: "Taylorfirstlook015",
    display: "landscape",
  },
  {
    id: 23,
    imageName: require("../pics/Lifestyle/Taylorsecondlookonsteps022.JPG"),
    content: "Taylorsecondlookonsteps022",
    display: "landscape",
  }
];

const LifestylePort = () => {
  const [currentPhotoLifestyle, setcurrentPhotoLifestyle] = useState(null);
  const [isLandscape, setisLandscape] = useState(null);

  const imageOrientation = (img) => {
    if (img === "landscape") {
      setisLandscape(true);
    } else {
      setisLandscape(false);
    }
    console.log(currentPhotoLifestyle);
  };

  const listImages = imagesLifestyle.map((image) => {
    return (
      <div className="card" key={image.id}>
        <img
          className="card-img"
          onClick={() => {
            setcurrentPhotoLifestyle(image.imageName);
            imageOrientation(image.display);
          }}
          src={image.imageName}
          alt={image.content}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        />
        <ModalFashionPort
          photo={currentPhotoLifestyle}
          landscape={isLandscape}
        />
      </div>
    );
  });

  return (
    <div className="bg-light container-fluid">
      <Helmet>
        <body className="bg-light"></body>
      </Helmet>
      <div className="card-columns">
        {listImages}
        </div>
    </div>
  );
};

export default LifestylePort;
