import React from "react";
import Home from "./components/Home";
import About from "./components/About";
import Sessions from "./components/Sessions";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import Business from "./components/Business";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Influencer from "./components/Influencer";
import LifestylePort from "./components/LifestylePort";
import FashionPort from "./components/FashionPort";
import SmallBusinessPort from "./components/SmallBusinessPort";
import VideographyPort from "./components/VideographyPort";

const landingPage = () => {
  return (
    <main>
      <Home />
      <Switch>
        <Route path="/about" component={About} />
        <Route path="/sessions" component={Sessions} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/contact" component={Contact} />
        <Route path="/business-sessions" component={Business} />
        <Route path="/influencer-sessions" component={Influencer} />
        <Route path="/lifestyle-portfolio" component={LifestylePort} />
        <Route path="/fashion-portfolio" component={FashionPort} />
        <Route path="/small-business-portfolio" component={SmallBusinessPort} />
        <Route path="/videography-portfolio" component={VideographyPort} />
      </Switch>
    </main>
  );
};

export default landingPage;
