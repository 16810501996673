import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import axios from "axios";
import "../App.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  //handle inputs
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const formSubmit = (e) => {
    //e.preventDefault();
    let data = {
      name: name,
      email: email,
      message: message,
    };

    axios.defaults.port = 3001;

    axios
      .post("https://larabellephotography.com:3001/api/form/", data)
      .then((response) => {
        setSent(true);
        console.log("AMY Response from API: ", response);
      }, resetForm())
      .catch(() => {
        console.log("AMY: inside axios.post /api/form FAIL:");
      });
  };

  const validateEmail = async (value) => {
    const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    await sleep(1000);
    if (value.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };

  const resetForm = () => {
    console.log("AMY: resetForm() triggered");
    setName("");
    setEmail("");
    setMessage("");
    console.log("AMY: name: ", name);
    console.log("AMY: sent: ", sent);
    setTimeout(() => {
      setSent(false);
    }, 4000);
  };

  return (
    <div className="page-box bg-light">
      <Helmet>
        <body className="bg-light"></body>
      </Helmet>
      <div className="container contact-container writing-text mb-4 mt-5">
        <div className="mb-4 title-text text-black-50 font-weight-normal">
          Let’s Collaborate!
        </div>
        <br />
        <div className="sessions-text">
          I split my time between New York City and the greater Richmond, VA
          area — but I would love to come to you, wherever that may be. I am
          available for worldwide travel!
          <br />
          <br />
          Email: info@larabellephotography.com
          <br />
          <br />
        </div>
        <form noValidate>
          <div className="form-group mb-4">
            <label htmlFor="name">Name</label>
            <input
              name="name"
              className="form-control form-control-sm"
              type="text"
              id="name"
              placeholder="Enter name"
              value={name}
              onChange={handleName}
              ref={register({ required: true })}
            />
            {errors.name && (
              <div className="invalid-feedback">Please enter name</div>
            )}
          </div>
          <div className="form-group mb-4">
            <label htmlFor="email">Email address</label>
            <input
              name="email"
              className="form-control form-control-sm"
              type="email"
              id="email"
              placeholder="Enter email"
              value={email}
              onChange={handleEmail}
              ref={register({ required: true, validate: validateEmail })}
            />
            {errors.email && errors.email.type === "required" && (
              <span className="invalid-feedback">Email required</span>
            )}

            {errors.email && errors.email.type === "validate" && (
              <span className="invalid-feedback">Please enter valid email</span>
            )}

            <small className="form-text text-muted">
              Your email will never be shared
            </small>
          </div>
          <div className="form-group mb-4">
            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              className="form-control"
              id="message"
              rows="3"
              value={message}
              onChange={handleMessage}
              // ref={register({required: true})}
            ></textarea>
          </div>
          <button
            className="btn btn-light btn-outline-secondary"
            type="submit"
            onClick={handleSubmit(formSubmit)}
          >
            Submit
          </button>
          <span
            className={sent ? "appear alert alert-success ml-4 w-100" : "msg"}
          >
            Message Sent
          </span>
        </form>
      </div>
    </div>
  );
};

export default Contact;
