import React, { useState } from "react";
import Helmet from "react-helmet";
import ModalFashionPort from "../components/ModalFashionPort";

const imagesBusiness = [
  {
    id: 1,
    imageName: require("../pics/SmallBusinessPortfolio/Grace003.JPG"),
    content: "Grace003",
    display: "portrait",
  },
  {
    id: 2,
    imageName: require("../pics/SmallBusinessPortfolio/Highwater007.JPG"),
    content: "Highwater007",
    display: "landscape",
  },
  {
    id: 3,
    imageName: require("../pics/SmallBusinessPortfolio/Highwater001.JPG"),
    content: "Highwater001",
    display: "portrait",
  },
  {
    id: 4,
    imageName: require("../pics/SmallBusinessPortfolio/Highwater002.JPG"),
    content: "Highwater002",
    display: "portrait",
  },
  {
    id: 5,
    imageName: require("../pics/SmallBusinessPortfolio/Ipanema012.JPG"),
    content: "Ipanema012",
    display: "landscape",
  },
  {
    id: 6,
    imageName: require("../pics/SmallBusinessPortfolio/Highwater003.JPG"),
    content: "Highwater003",
    display: "portrait",
  },
  {
    id: 7,
    imageName: require("../pics/SmallBusinessPortfolio/Ipanema016.JPG"),
    content: "Ipanema016",
    display: "landscape",
  },
  {
    id: 8,
    imageName: require("../pics/SmallBusinessPortfolio/Highwater004.JPG"),
    content: "Highwater004",
    display: "Portrait",
  },
  {
    id: 9,
    imageName: require("../pics/SmallBusinessPortfolio/Highwater005.JPG"),
    content: "Highwater005",
    display: "Portrait",
  },
  {
    id: 10,
    imageName: require("../pics/SmallBusinessPortfolio/Highwater006.JPG"),
    content: "Highwater006",
    display: "portrait",
  },

  {
    id: 11,
    imageName: require("../pics/SmallBusinessPortfolio/Ipanema006.JPG"),
    content: "Ipanema006",
    display: "portrait",
  },
  {
    id: 12,
    imageName: require("../pics/SmallBusinessPortfolio/Isabelle013.JPG"),
    content: "Isabelle013",
    display: "portrait",
  },
  {
    id: 13,
    imageName: require("../pics/SmallBusinessPortfolio/McLaren003.JPG"),
    content: "McLaren003",
    display: "portrait",
  },
  {
    id: 14,
    imageName: require("../pics/SmallBusinessPortfolio/Sen002.JPG"),
    content: "Sen002",
    display: "portrait",
  },
  {
    id: 15,
    imageName: require("../pics/SmallBusinessPortfolio/Sen007.JPG"),
    content: "Sen007",
    display: "landscape",
  },
  {
    id: 16,
    imageName: require("../pics/SmallBusinessPortfolio/TeszraStudio0006.JPG"),
    content: "TeszaraStudio0006",
    display: "landscape",
  },
  {
    id: 17,
    imageName: require("../pics/SmallBusinessPortfolio/ThreeCrown001.JPG"),
    content: "ThreeCrown001",
    display: "portrait",
  }
];

const LifestylePort = () => {
  const [currentPhotoBusiness, setcurrentPhotoLifestyle] = useState(null);
  const [isLandscape, setisLandscape] = useState(null);

  const imageOrientation = (img) => {
    if (img === "landscape") {
      setisLandscape(true);
    } else {
      setisLandscape(false);
    }
    console.log(currentPhotoBusiness);
  };

  const listImages = imagesBusiness.map((image) => {
    return (
      <div className="card" key={image.id}>
        <img
          className="card-img w-100 h-100"
          onClick={() => {
            setcurrentPhotoLifestyle(image.imageName);
            imageOrientation(image.display);
          }}
          src={image.imageName}
          alt={image.content}
          data-toggle="modal"
          data-target="#exampleModalCenter"
        />
        <ModalFashionPort
          photo={currentPhotoBusiness}
          landscape={isLandscape}
        />
      </div>
    );
  });

  return (
    <div className="bg-light container-fluid">
      <Helmet>
        <body className="bg-light"></body>
      </Helmet>
      <div className="card-columns">{listImages}</div>
    </div>
  );
};

export default LifestylePort;