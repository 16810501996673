import React from 'react';
import {Helmet} from 'react-helmet';

const Portfolio = () => {
    return(
        <div className="bg-light container-fluid">
            <Helmet>
                <body className="bg-light"></body>
            </Helmet>
            Portfolio
        </div>
    )
}

export default Portfolio;