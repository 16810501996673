import React from "react";
import { Helmet } from "react-helmet";
import "../App.css";
import "../bootstrap-social.css";
import PortPic from "../pics/AboutUpdate.JPG";

const About = () => {
  return (
    <div className="bg-light container-fluid">
      <Helmet>
        <body className="bg-light"></body>
      </Helmet>
      <div className="container w-55 h-45 writing-text mb-4 card-rows style justifiy-content-center">
        <div className="d-flex justify-content-center row-hl">
          <div className="mb-4 title-text text-black-50 font-weight-normal">
            Welcome
          </div>
        </div>
        <div className="row mb-5  ">
          <div className="col-sm-4 col-md-6 col-lg-4 col-xl-6">
            <img src={PortPic} alt="laura" className="img-fluid p-4" />
          </div>
          <div className="col-sm-7 col-md-5 col-lg-5 col-xl-6">
            <div className="card-body w-60">
              <div className="text-muted about-text">
                Hello! My name is Larabelle. As a photographer I am passionate
                about finding and capturing the beauty inside every soul. Having
                previously spent a decade as a singer/songwriter in the music
                industry, I decided in 2015 to transition into using photography
                as my creative outlet. Now I help others create the imagery that
                will help them to pursue their dreams.
                <br />
                <br />
                Growing up as a curvy girl in the 90’s I understand what it’s
                like to feel pressured to meet the impossible beauty standards
                set in the media. That is the reason I have such a passion for
                creating beautiful images of diverse subjects that are as close
                to reality as possible.
                <br />
                <br />
                Thankfully, I’ve been fortunate to work with many models and
                social media artists and influencers who are unashamed of
                unretouched cellulite and curves, and my photographs have been
                published online in Buzzfeed, Cosmopolitan, People, Women’s
                Health, Refinery29, Yahoo, Fox News, The Unedit, and others.
                <br />
                <br />
                I understand that realistic, raw photography may not be the
                right fit for every client, whether you’re looking for portraits
                or product photography for a small business. However, if you are
                passionate about creating real, unretouched, beautiful
                photographs then I want to create art with you! Together let’s
                challenge beauty standards, spread your art, and build your
                business. I hope to hear from you soon!
                <br />
                <br />
                <div className="d-flex d-block">
                <a className="btn btn-light btn-outline-secondary mt-3" target="_blank"
                    href="https://www.instagram.com/larabellenewyork/?hl=en "
                    rel="noopener noreferrer">
                @LarabelleNewYork
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
