// import React from 'react';


// const ModalFashionCarousal = ({photo, landscape}) => {

//       return(
        
//         <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
//             <div className={landscape ? "modal-dialog modal-lg modal-dialog-centered modal-margin-landscape": "modal-dialog modal-lg modal-dialog-centered modal-margin-portrait"}>
//               <img className="container-fluid"  src={photo} alt="current"/>
//             </div>
//         </div>
//       )
// }


// export default ModalFashionCarousal;

import React from 'react'

const modalFashionCarousal = ({photo, landscape}) => {
  console.log(landscape)
    return(
      <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div className={landscape ? "modal-dialog modal-lg modal-dialog-centered modal-margin-landscape": "modal-dialog modal-lg modal-dialog-centered modal-margin"}>
            <img className="container-fluid"  src={photo} alt="current"/>
          </div>
      </div>
    )
}

export default modalFashionCarousal;