import React from 'react';
import Helmet from 'react-helmet';

const VideographyPort = () => {
    return (
        <div className="bg-light container-fluid">
            <Helmet>
                <body className="bg-light"></body>
            </Helmet>
            <div>
            <div className="d-flex flex-wrap">
          <div className="container d-flex justify-content-center">
              <div className="card-rows col-sm-10 col-md-10 col-lg-10 col-xl-10 mt-3">
                <div className="card mb-3">
                <div className="embed-responsive embed-responsive-16by9">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/trmPjRSpbYM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Fashion"></iframe>
                </div>
                  <div className="d-flex flex-column">
                    <h4 className="card-title videography-font d-flex justify-content-center pt-3">
                      Eauseenon Fashion Show
                    </h4>
                  </div>
                  <div className="d-flex flex-column">
                    <h4 className="card-title videography-font d-flex justify-content-center pb-2">
                      New York City 2016
                    </h4>
                  </div>
                </div>
                <div className="card mb-3">
                <div className="embed-responsive embed-responsive-16by9">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/BN-rYdKZDYs" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Fashion"></iframe>
                </div>
                <div className="d-flex flex-column">
                    <h4 className="card-title videography-font d-flex justify-content-center pt-3">
                      Music Video "Find the Lite" 
                    </h4>
                  </div>
                  <div className="d-flex flex-column">
                    <h4 className="card-title videography-font d-flex justify-content-center">
                      Evelyn K
                    </h4>
                  </div>
                  <div className="d-flex flex-column">
                    <h4 className="card-title videography-font d-flex justify-content-center pb-2">
                      New York City 2020
                    </h4>
                  </div>
                </div>
                <div className="card mb-3">
                <div className="embed-responsive embed-responsive-16by9">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/WP543JdcgUs" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Fashion"></iframe>
                </div>
                <div className="d-flex flex-column">
                    <h4 className="card-title videography-font d-flex justify-content-center pt-3">
                      Music Video "Breathe" 
                    </h4>
                  </div>
                  <div className="d-flex flex-column">
                    <h4 className="card-title videography-font d-flex justify-content-center">
                      Moses Christopher
                    </h4>
                  </div>
                  <div className="d-flex flex-column">
                    <h4 className="card-title videography-font d-flex justify-content-center pb-2">
                      Paris/Virginia 2020
                    </h4>
                  </div>
                </div>
                </div>
                </div>
                </div>
            </div>
            </div>
        )
}

export default VideographyPort;