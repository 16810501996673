import React from 'react';
import Helmet from 'react-helmet';


const Business = () => {
    return (
        <div className="bg-light container-fluid">
            <Helmet>
                <body className="bg-light"></body>
            </Helmet>
            <div className="card-columns">
                <div className="card">
                    <img className="card-img" src="https://placeimg.com/900/300/animals" alt="animalsthree"/>
                </div>
                <div className="card">
                    <img className="card-img" src="https://placeimg.com/900/600/animals" alt="animalstwo"/>
                </div>
                <div className="card">
                    <img className="card-img" src="https://placeimg.com/900/200/arch" alt="archtwo"/>
                </div>
                <div className="card">
                    <img className="card-img" src="https://placeimg.com/900/400/nature" alt="natureone"/>
                </div>
                <div className="card">
                    <img className="card-img" src="https://placeimg.com/900/300/arch" alt="arch"/>
                </div>
                <div className="card">
                    <img className="card-img" src="https://placeimg.com/940/500/animals" alt="animals"/>
                </div>
                <div className="card">
                    <img className="card-img" src="https://placeimg.com/900/900/people" alt="People"/>
                </div>
                <div className="card">
                    <img className="card-img" src="https://placeimg.com/940/800/nature" alt="nature"/>
                </div>

                <div className="card">
                    <img className="card-img" src="https://placeimg.com/940/300/tech" alt="tech"/>
                </div>
            </div>
        </div>
    )
}

export default Business;